import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {



  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");

    // You may also want to redirect to the login page or perform any other cleanup
    navigate("/AdminLogin");
  };

  return (
    <div className="pl-[40px] flex flex-col justify-between  min-h-screen  pb-[69px]">
      <div>
        <Link to="/Dashboard">
        <div>
          <img src={require("../assets/logo.png")} alt="" />
        </div>
        </Link>
        <div className="mt-[52px] flex justify-center text-[18px] items-center font-medium fontClass1 text-white bg-gradient-to-r from-[#54AACD] to-[#3A75BB] w-[253px] h-[56px] text-center rounded-[8px]">
          Portfolio’s
        </div>
        {/* <div className="flex mt-[33px] justify-start text-[18px] text-[black] items-center font-normal fontClass1 text-black">
          Admin Edit portfolio
        </div> */}
        <Link to="/AddPort">
        <div className="flex mt-[33px] justify-start text-start text-[18px] text-[black] items-center font-normal fontClass1 text-black">
          Admin Add portfolio
        </div>
        </Link>
      </div>

      {/* Move the logout image to the bottom */}
      <img
        src={require("../assets/List Menu.png")}
        alt=""
        className="mt-auto   cursor-pointer w-[67px] h-[23px] "
        onClick={handleLogout}
      />
    </div>
  );
};

export default Sidebar;
