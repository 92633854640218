import logo from "./logo.svg";
import "./App.css";
import Signin from "./Compoent/Signin/Signin";
import Sidebar from "./Compoent/SideBar/Sidebar";
import AddPort from "./Compoent/AddPortFolio/AddPort";
import Dashboard from "./Compoent/AddPortFolio/Dashboard";
import { Routes, Route } from "react-router-dom";
import NavScrollExample from "./Compoent/UserPannel/UserNavbar";
import Footer from "./Compoent/UserPannel/Footer";
import Home from "./Compoent/UserPannel/Home";
import Detail from "./Compoent/UserPannel/Detail";
import SimpleSlider from "./Compoent/UserPannel/Slider";
import Value from "./Compoent/ValueComponent/Value";
import EditPort from "./Compoent/AddPortFolio/EditPort";
import AutoPlay from "./Compoent/UserPannel/MainSlider";
import Forgot from "./Compoent/Signin/Forgot";
import Reset from "./Compoent/Signin/Reset";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={} /> */}

        <Route path="/AdminLogin" element={<Signin />} />
        <Route path="/Forgot" element={<Forgot />} />
        <Route path="/resetPassword" element={<Reset />} />

        <Route path="/AddPort" element={<AddPort />} />
        <Route path="/edit" element={<EditPort />} />
        <Route path="/AutoPlay" element={<AutoPlay />} />

        <Route path="/" element={<Home />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/Detail" element={<Detail />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Value" element={<Value />} />
      </Routes>
    </div>
  );
}

export default App;
