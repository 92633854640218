import React from "react";

const Footer = () => {
  return (
    <>
      <div className="bg-[white]   flex flex-col gap-[50px] lg:gap-[0px] lg:flex-row  justify-around items-center  lg:items-   paddingClassforfooter  pt-[86px] pb-[86px] ">
        <div className="flex justify-center">
          <img
            src={require("../assets/logo.png")}
            alt=""
            className="fotWifth"
          />
        </div>

        <div className=" lg:border-r-[1.24px] border-r-[#676767] pr-[20px]    lg:mt-[0px] flex-col justify-between   h-[121px] ">
         <div  className="text-gray-700  text-[18.83px] font-normal leading-[28.24px] text-start">
          Phone :
         </div>
          <div className="flex   gap-[20px] mt-[10px] ">
            <img
              src={require("../assets/Home/Vector.png")}
              alt=""
              className="w-[30px] h-[30]"
            />
            <div className="text-gray-700 text-base font-normal font-poppins break-words">
            780-297-7663
            </div>
          </div>
          <div  className="text-gray-700  mt-[10px] text-[18.83px] font-normal leading-[28.24px] text-start">
          Email :
         </div>
          <div className="flex justify-center items-center gap-[25px] mt-[10px]">
            <img
              src={require("../assets/Home/ic_sharp-email.png")}
              alt=""
              className="w-[30px] h-[30]"
            />
            <div className="text-gray-700 text-base font-normal font-poppins break-words">
            Admin@roofyeg.ca
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start  h-[80px]  lg:h-[121px]  gap-[10px]  lg:border-r-[1.24px] border-r-[#676767]     lg:mt-[0px] lg:pr-[20px]">
        <div  className=" text-gray-700   text-[18.83px] font-normal leading-[28.24px] text-start">
          Location :
         </div>

         <div className="flex gap-[20px]">

          <div className="w-[30px] mt-[10px] ">

            
            <img
              src={require("../assets/loc.png")}
              alt=""
              className="w-[30px] h-[30px]"
            />
          </div>
          <div className="  lg:w-[180px] text-gray-700 text-start font-normal font-poppins break-words">
            Serving Edmonton & Area
          </div>
         </div>
        </div>

        <div className=" gap-[25px]  flex-col  flex-col justify-start items-center gap-[20px]  pb-[64px]">
          <div className=" text-gray-700 text-start font-normal font-poppins break-words">
            Follow Us:
          </div>
          <div className="flex mt-[14px] items-center  gap-[20px] ">
            <img src={require("../assets/Vector (1).png")} alt="" />
            <img src={require("../assets/Vector (2).png")} alt="" />
            <img src={require("../assets/Vector (3).png")} alt="" />
          </div>
        </div>
      </div>

      <div className="text-black text-center font-normal font-raleway leading-7 break-words">
        © 2024. All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
