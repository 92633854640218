import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { RxHamburgerMenu } from "react-icons/rx";
const OffCanvas = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div >
      <button onClick={toggleMenu}>
        <RxHamburgerMenu color="black" size={30} />
      </button>

      <div className={`off-canvas ${isMenuOpen ? "open" : ""}`}>
        {/* Off-canvas content goes here */}
        <div>
          <button onClick={toggleMenu}  className="p-[20px] text-start flex justify-start " >
            <GrClose color="red"   size={30} />
          </button>
          <div className='pl-[40px] flex flex-col justify-between  min-h-screen  pb-[69px]'>

<div>
  <div>
    <img src={require("../assets/logo.png")} alt="" />
  </div> 
  <div className='mt-[52px] flex justify-center text-[18px] items-center font-medium fontClass1 text-white bg-gradient-to-r from-[#54AACD] to-[#3A75BB] w-[253px] h-[56px] text-center rounded-[8px]'>
    Portfolio’s
  </div>
  <div className='flex mt-[33px] justify-start text-[18px] text-[black] items-center font-normal fontClass1 text-black'>
    Admin Edit portfolio
  </div>
  <div className='flex mt-[33px] justify-start text-start text-[18px] text-[black] items-center font-normal fontClass1 text-black'>
    Admin Add portfolio
  </div>
</div> 

{/* Move the logout image to the bottom */}
<img src={require("../assets/List Menu.png")} alt="" className="mt-auto  w-[67px] h-[23px] " />

</div>
        </div>
      </div>
    </div>
  );
};

export default OffCanvas;
