import React, { useState, useRef } from "react";
import Sidebar from "../SideBar/Sidebar";
import OffCanvas from "../SideBar/OffCanvas";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL, IMG_URL } from "../../instance";
const EditPort = () => {
  const location = useLocation();

  // Access the data passed from the previous page
  //   const { state } = location;
  const data = location.state.item;

  console.log("dataOnEdit", data);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const handleImageClick1 = () => {
    fileInputRef1.current.click();
  };
  const handleImageClick2 = () => {
    fileInputRef2.current.click();
  };
  const handleImageClick3 = () => {
    fileInputRef3.current.click();
  };
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);

  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);

  const [projectTitle, setProjectTitle] = useState(data.projectTitle);
  const [description, setDescription] = useState(data.description);
  const [projectTitle1, setProjectTitle1] = useState(data.projectTitle1);
  const [description1, setDescription1] = useState(data.description1);
  const [projectTitle2, setProjectTitle2] = useState(data.projectTitle2);
  const [productUsed, setProductUsed] = useState(data.productUsed);

  // State for images
  const [project1Image, setProject1Image] = useState(data.project1Image);
  const [project1Image1, setProject1Image1] = useState(data.project1Image1);
  const [project1Image12, setProject1Image12] = useState(data.project1Image12);
  const [homeproject1Image, setHomeProject1Image] = useState(
    data.homeproject1Image
  );

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setProject1Image(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the base64-encoded string to the state
        setImagePreview(reader.result);
      };

      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleFileChange1 = (e) => {
    const selectedFile = e.target.files[0];
    setProject1Image1(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the base64-encoded string to the state
        setImagePreview1(reader.result);
      };

      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    setProject1Image12(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the base64-encoded string to the state
        setImagePreview2(reader.result);
      };

      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleFileChange3 = (e) => {
    const selectedFile = e.target.files[0];
    setHomeProject1Image(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the base64-encoded string to the state
        setImagePreview3(reader.result);
      };

      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Append files to FormData
      formDataToSend.append("projectTitle", projectTitle);
      formDataToSend.append("description", description);
      formDataToSend.append("projectTitle1", projectTitle1);
      formDataToSend.append("projectTitle2", projectTitle2);

      formDataToSend.append("description1", description1);
      formDataToSend.append("productUsed", productUsed);
      formDataToSend.append("project1Image", project1Image);

      formDataToSend.append("project1Image1", project1Image1);
      formDataToSend.append("project1Image12", project1Image12);
      formDataToSend.append("homeproject1Image", homeproject1Image);

      const response = await axios.put(
        `${BASE_URL}/api/data/${data._id}`,
        formDataToSend
      );
      Swal.fire({
        icon: "success",
        title: "Update",
        text: "This Data Update Successfully",
      });
      console.log("Response from server:", response.data);

      // Handle success or update UI accordingly
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error or update UI accordingly
    }
  };

  return (
    <div className="flex gap-[92px] w-full pl-[40px] pr-[40px] pt-[55px] ">
      <div className="hidden lg:flex lg:w-1/4">
        <Sidebar />
      </div>

      {/* Off-canvas menu for screens smaller than 1024px */}
      <div className="lg:hidden absolute top-[30px] left-[30px]">
        <OffCanvas />
      </div>
      <div className="flex gap-[92px] w-full justify-between  pb-[69px]">
        <div className="  pt-[100px] lg:pt-[161px] w-[100%]   lg:w-[74%] ">
          <div className="text-[16px] fontClass2 text-start font-medium leading-[32px] ">
            Project title
          </div>
          <div className="text-[16px] mt-[10px] text-[#484848] fontClass2 font-medium leading-[32px]  bg-[#D5D5D5] rounded-[11px]  h-[54px] w-full ">
            <input
              type="text"
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
              className="focus:outline-none focus:border-none p-2 rounded-md bg-[#D5D5D5] h-[54px] w-full pl-[20px]"
            />
          </div>

          <div className="text-[16px] mt-[25px] fontClass2 text-start font-medium leading-[32px] ">
            Discription
          </div>
          <div className="text-[14px] mt-[10px] text-[#484848] fontClass2 font-medium leading-[32px]  p-[10px] bg-[#D5D5D5] rounded-[11px]  h-[252px] w-full ">
            <textarea
              name=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full   bg-[#D5D5D5] rounded-[11px]  h-[222px] w-full focus:outline-none focus:border-none"
            ></textarea>
          </div>
          <div className=" h-[324px]  md:h-[624px] w-full rounded-[11px] relative flex justify-center items-center mt-[32px] bg-[#D5D5D5]">
            <label htmlFor="fileInput" className="cursor-pointer relative bg-[white] rounded-[50%] z-2">
              <div onClick={handleImageClick}>
                <img src={require("../assets/carbon_add-filled.png")} alt="" />
              </div>
            </label>
            <div className="p-[10] text-center w-full h-full absolute z-1 ">
              <div className=" w-full h-full">
                <img
                  src={
                    imagePreview
                      ? imagePreview
                      : `https://roofyeg.ca/profile/${data?.project1Image}`
                  }
                  alt="Uploaded"
                  className="w-[100%] h-[100%]"
                />
              </div>
            </div>

            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="text-[16px] fontClass2 text-start font-medium leading-[32px] mt-[32px] ">
            Project title
          </div>
          <div className="text-[16px] mt-[25px] text-[#484848] fontClass2 font-medium leading-[32px]  bg-[#D5D5D5] rounded-[11px]  h-[54px] w-full ">
            <input
              type="text"
              value={projectTitle1}
              onChange={(e) => setProjectTitle1(e.target.value)}
              className="focus:outline-none focus:border-none p-2 rounded-md bg-[#D5D5D5] h-[54px] w-full pl-[20px]"
            />
          </div>

          <div className="text-[16px] mt-[25px] fontClass2 text-start font-medium leading-[32px] ">
            Discription
          </div>
          <div className="text-[14px] mt-[10px] text-[#484848] fontClass2 font-medium leading-[32px]  p-[10px] bg-[#D5D5D5] rounded-[11px]  h-[252px] w-full ">
            <textarea
              name=""
              value={description1}
              onChange={(e) => setDescription1(e.target.value)}
              className="w-full   bg-[#D5D5D5] rounded-[11px]  h-[222px] w-full focus:outline-none focus:border-none"
            ></textarea>
          </div>
          <div className="flex flex-col md:flex-row gap-[10px] md:gap-[28px]">
            <div className="h-[308px] w-full rounded-[11px] flex  relative  justify-center items-center mt-[32px] bg-[#D5D5D5]">
              <label htmlFor="fileInput" className="cursor-pointer relative z-2 bg-[white] rounded-[50%]">

                <div onClick={handleImageClick1}>
                  <img
                    src={require("../assets/carbon_add-filled.png")}
                    alt=""
                  />
                </div>

              </label>
              <div className="p-[10px] w-full h-full text-center absolute z-1">
                <div className="w-full h-full">
                  <img
                    src={
                      imagePreview1
                        ? imagePreview1
                        : `${BASE_URL}/profile/${data?.project1Image1}`
                    }
                    alt="Uploaded"
                    className="w-[100%] h-[100%]"

                  />
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef1}
                className="hidden"
                onChange={handleFileChange1}
              />
            </div>
            <div className="h-[308px] w-full rounded-[11px] flex justify-center relative items-center mt-[32px] bg-[#D5D5D5]">
              <label htmlFor="fileInput" className="cursor-pointer bg-[white] rounded-[50%] relative z-10 ">

                <div onClick={handleImageClick2} className="  z-10" >
                  <img
                    src={require("../assets/carbon_add-filled.png")}
                    alt=""
                    className="w-[100%] h-[100%]"
                  />
                </div>

              </label>
              <div className="pt-[10px] w-full h-full   absolute  z-1 text-center">
                <div className="w-full h-full">

                  <img
                    src={
                      imagePreview2
                        ? imagePreview2
                        : `${BASE_URL}/profile/${data?.project1Image12}`
                    }
                    alt="Uploaded"
                    className="w-[100%] h-[100%]"
                  />
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef2}
                className="hidden"
                onChange={handleFileChange2}
              />
            </div>
          </div>

          <div className="mt-[107px] text-center text-[24px]  text-black fontClass1 font-medium leading-[32px] ">
            Home page{" "}
          </div>
          <div className="text-[16px] fontClass2 text-start font-medium leading-[32px] mt-[18px] ">
            Project title
          </div>
          <div className="text-[16px] mt-[25px] text-[#484848] fontClass2 font-medium leading-[32px]  bg-[#D5D5D5] rounded-[11px]  h-[54px] w-full ">
            <input
              value={projectTitle2}
              type="text"
              onChange={(e) => setProjectTitle2(e.target.value)}
              className="focus:outline-none focus:border-none p-2 rounded-md bg-[#D5D5D5] h-[54px] w-full pl-[20px]"
            />
          </div>
          {/* <div className="text-[16px] fontClass2 text-start font-medium leading-[32px] mt-[32px] ">
            PROJECT TYPE
          </div>
          <div className="text-[16px] mt-[25px] text-[#484848] fontClass2 font-medium leading-[32px]  bg-[#D5D5D5] rounded-[11px]  h-[54px] w-full ">
            <input
              type="text"
              className="focus:outline-none focus:border-none p-2 rounded-md bg-[#D5D5D5] h-[54px] w-full pl-[20px]"
            />
          </div> */}
          <div className="text-[16px] fontClass2 text-start font-medium leading-[32px] mt-[32px] ">
            PRODUCTS USED
          </div>
          <div className="text-[16px] mt-[25px] text-[#484848] fontClass2 font-medium leading-[32px]  bg-[#D5D5D5] rounded-[11px]  h-[54px] w-full ">
            <input
              type="text"
              value={productUsed}
              onChange={(e) => setProductUsed(e.target.value)}
              className="focus:outline-none focus:border-none p-2 rounded-md bg-[#D5D5D5] h-[54px] w-full pl-[20px]"
            />
          </div>
          <div className="h-[324px]    md:h-[460px] w-full rounded-[11px]  relative flex justify-center items-center mt-[32px] bg-[#D5D5D5]">
            <label htmlFor="fileInput" className="cursor-pointer relative bg-[white] rounded-[50%] z-2">
              <div onClick={handleImageClick3} className="bg-grey rounded-[50%]">
                <img src={require("../assets/carbon_add-filled.png")} alt="" />
              </div>
            </label>
            <div className="p-[10px]  w-full h-full text-center   absolute z-1 ">
              <div className="w-full h-full">
                <img
                  src={
                    imagePreview3
                      ? imagePreview3
                      : `${BASE_URL}/profile/${data?.homeproject1Image}`
                  }
                  alt="Uploaded"
                  className="w-[100%] h-[100%]"
                />
              </div>
            </div>

            <input
              type="file"
              ref={fileInputRef3}
              className="hidden"
              onChange={handleFileChange3}
            />
          </div>

          <div
            onClick={handleSubmit}
            className="cursor-pointer mx-auto mt-[74px] flex justify-center text-[16px] text-center  items-center font-medium fontClass1  text-white bg-gradient-to-r from-[#54AACD] to-[#3A75BB]   w-[220px] h-[46px] text-center  rounded-[11px] "
          >
            save
          </div>
        </div>

        <div className=" absolute flex gap-[10px] justify-center items-center  top-[30px] right-[30px] lg:top-[55px] ">
          <img
                                  src={require("../assets/logo.png")}


            alt=""
            className="h-[43px] w-[43px]"
          />
          <div className="text-[black] text-[18px] fontClass1 font-medium ">
            {storedUser.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPort;
