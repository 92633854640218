import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import DetailImg from "../assets/Home/d1 (2).png";
import { GrFormNext } from "react-icons/gr";
import { IoChevronBackOutline } from "react-icons/io5";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../instance";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    title: "Portfolio",
    subTitle: "BP Mystique Roof Replacement",
    label: "San Francisco – Oakland Bay Bridge, United States",
    productUsed: "PRODUCTS USED",
    PRODCUTbODY: "BP MYSTIQUE SHINGLES",
    imgPath: DetailImg,
  },
  {
    title: "Portfolio",
    subTitle: "BP Mystique Roof Replacement",
    label: "San Francisco – Oakland Bay Bridge, United States",
    productUsed: "PRODUCTS USED",
    PRODCUTbODY: "BP MYSTIQUE SHINGLES",
    label: "Bird",
    imgPath: DetailImg,
  },
  {
    title: "Portfolio",
    subTitle: "BP Mystique Roof Replacement",
    label: "San Francisco – Oakland Bay Bridge, United States",
    productUsed: "PRODUCTS USED",
    PRODCUTbODY: "BP MYSTIQUE SHINGLES",
    label: "Bali, Indonesia",
    imgPath: DetailImg,
  },
  {
    title: "Portfolio",
    subTitle: "BP Mystique Roof Replacement",
    label: "San Francisco – Oakland Bay Bridge, United States",
    productUsed: "PRODUCTS USED",
    PRODCUTbODY: "BP MYSTIQUE SHINGLES",
    label: "Goč, Serbia",
    imgPath: DetailImg,
  },
];

function SimpleSlider() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/allData`); // Update the URL based on your API server
      setData(response?.data);
      console.log("response", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log("data", data);

    fetchData();
  }, []);

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="flex justify-center items-start  w-full ">
      <div
        sx={{ maxWidth: 400, flexGrow: 1 }}
        className="    w-full  xl:w-[1300px] mx-auto "
      >
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 2,
            bgcolor: "",
          }}
        >
          {/* <Typography>{images[activeStep].label}</Typography> */}
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {data.map((step, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Link to="/Detail" state={{ step }}>
                  <div className=" pl-[20px]  pr-[20px]  flex   justify-between md:justify-center items-start gap-[20px]  md:gap-[50px] lg:gap-[133px]">
                    <div>
                      <div className="pt-[20px]  md:pt-[71px] text-start  text-[#54AACD]  text-[16px]  md:text-[22px] font-bold font-raleway md:leading-[28.6px] break-words">
                        Portfolio
                      </div>

                      <div class="md:w-[300px] mt-[14px] text-start text-[#000000]  text-[20px] md:text-[32px] font-bold md:leading-[41.7px] font-lora">
                        {step.projectTitle2}
                      </div>

                      <div className=" mt-[20px] md:mt-[66px]">
                        <span class=" text-[#54AACD] text-[16px]  md:text-[22px] font-bold md:leading-[35.4px] font-lora">
                          PRODUCTS USED
                          <br />
                        </span>
                        <span class="text-[#616161] text-base  text-[16px]  md:text-[20px] font-normal leading-[32.4px] font-raleway">
                          {step.productUsed}
                        </span>
                      </div>
                      <div className=" mt-[20px] md:mt-[66px]">
                        <span class=" text-[#54AACD] text-[16px]  md:text-[22px] font-bold md:leading-[35.4px] font-lora">
                          Warranty Info
                          <br />
                        </span>
                        <span class="text-[#616161] text-base  text-[16px]  md:text-[20px] font-normal leading-[32.4px] font-raleway">
                          10 Years
                        </span>
                      </div>
                    </div>
                    <div className="pt-[43px] pb-[43px]">
                      <img
                        src={`${BASE_URL}/profile/${step.homeproject1Image}`}
                        alt=""
                        className=" w-[200px] h-[150px]  md:w-[300px] md:h-[300px] lg:w-[690px] lg:h-[488px]"
                      />
                    </div>
                  </div>
                </Link>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <div className="w-full  xl:w-[1300px] mx-auto">
          <div className=" md:ml-[200px]">
            <MobileStepper
              steps={maxSteps}
              style={{ background: "transparent" }}
              position=""
              variant=""
              autoPlay={false}
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  className=""
                  disabled={activeStep === maxSteps - 1}
                >
                  <GrFormNext color="black" size={24} />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <IoChevronBackOutline color="black" size={24} />
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleSlider;
