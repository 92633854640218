import React from "react";
import Slider from "react-slick";



function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="mt-[30px] flex justify-center items-center  pl-[80px] "
        onClick={onClick}
      >
       <img src={require("../assets/Value/Vector.png")} alt="" />
      </div>
    );
  }
  
  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="mt-[30px] flex justify-center items-center absolute bottom-0 right-[51%]"
        onClick={onClick}
      >
       <img src={require("../assets/Value/Vector (1).png")} alt="" />
       
      </div>
    );
  }

function MultipleItems() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container  gap-[4px] wdClass mx-auto ">
      <Slider {...settings}>
        <div className="mr-[10px]">
          {/* <div className="        mx-[10px] sShade p-[20px]">
            <div className="flex gap-[20px]">
              <div>
                <img src={require("../assets/Value/Ellipse 1.png")} alt="" />
              </div>
              <div className="text-start">
                <div className="text-start">Sydney</div>
                <div className="flex gap-[5px] mt-[10px]">
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="mt-[20px] fontClass2 text-[14px] leading-[24.7px] text-[#3E3E3E] text-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              eget mi eu dolor vehicula bibendum. Praesent vel ante quam. Sed
              diam nulla, laoreet ut bibendum non, ornare at massa. Nulla
              volutpat velit et enim var
            </div>
          </div> */}
        </div>
        <div className="mr-[10px]">
          <div className=" mx-[10px] sShade p-[20px]">
            <div className="flex gap-[20px]">
              <div>
                <img src={require("../assets/Value/Ellipse 2.png")} alt="" />
              </div>
              <div className="text-start">
                <div>Kratos</div>
                <div className="flex gap-[5px] mt-[10px]">
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="mt-[20px] fontClass2 text-[14px] leading-[24.7px] text-[#3E3E3E] text-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              eget mi eu dolor vehicula bibendum. Praesent vel ante quam. Sed
              diam nulla, laoreet ut bibendum non, ornare at massa. Nulla
              volutpat velit et enim var
            </div>
          </div>
        </div>
        <div className="mr-[10px]" >
          <div className=" mx-[10px] sShade p-[20px]">
            <div className="flex gap-[20px]">
              <div>
                <img src={require("../assets/Value/Ellipse 1.png")} alt="" />
              </div>
              <div className="text-start">
                <div>Sydney</div>
                <div className="flex gap-[5px] mt-[10px]">
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                  <img src={require("../assets/Value/star 8.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="mt-[20px] fontClass2 text-[14px] leading-[24.7px] text-[#3E3E3E] text-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              eget mi eu dolor vehicula bibendum. Praesent vel ante quam. Sed
              diam nulla, laoreet ut bibendum non, ornare at massa. Nulla
              volutpat velit et enim var
            </div>
          </div>
        </div>
        
      </Slider>
    </div>
  );
}

export default MultipleItems;
