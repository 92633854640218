import React, { Component } from "react";
import Slider from "react-slick";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
    return (
      <div  className="overflow-hidden">
        <Slider {...settings}>
          <div>
            <img
              src={require("../assets/ca1.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
          <div>
            <img
              src={require("../assets/ca2.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
          <div>
            <img
              src={require("../assets/ca3.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
          <div>
            <img
              src={require("../assets/ca4.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
          <div>
            <img
              src={require("../assets/ca5.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
          <div>
            <img
              src={require("../assets/ca6.png")}
              alt=""
              className="w-[173px] h-[54px]"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
