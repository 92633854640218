import React from "react";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className="flex justify-center "  style={{marginTop:"20px"}} >
    <nav>
      <ul className="pagination flex gap-[10px]">
        {generatePageNumbers().map((number) => (
          <li key={number} className="gap-[10px]">
            <button
              onClick={() => onPageChange(number)}
              className={`page-link gap-[10px] ${
                currentPage === number
                  ? " back-div1 gap-[10px] "
                  : " "
              }`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
