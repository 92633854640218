import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../SideBar/Sidebar";
import OffCanvas from "../SideBar/OffCanvas";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import axios from "axios";
import Pagination from "./Pagination";
import { BASE_URL, IMG_URL } from "../../instance";
const Dashboard = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/allData`); // Update the URL based on your API server
      setData(response?.data);
      console.log("response", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log("data", data);

    fetchData();
  }, []);
  const navigateToEditPage = (item) => {
    navigate({
      pathname: "/edit",
      state: { data: item },
    });
  };
  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage1 = 5;
  const startIndex1 = (currentPage1 - 1) * itemsPerPage1;
  const endIndex1 = startIndex1 + itemsPerPage1;
  const currentListings1 = data.slice(startIndex1, endIndex1);
  const deleteItem = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/data/${id}`);
      console.log("Item deleted successfully");
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "This Data Deleted Successfully",
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  const handlePageChange1 = (newPage) => {
    setCurrentPage1(newPage);
  };
  return (
    <div className="flex gap-[92px] w-full pl-[40px] pr-[40px] pt-[55px] ">
      <div className="hidden lg:flex lg:w-1/4">
        <Sidebar />
      </div>

      {/* Off-canvas menu for screens smaller than 1024px */}
      <div className="lg:hidden absolute top-[30px] left-[30px]">
        <OffCanvas />
      </div>
      <div className="flex gap-[92px] w-full justify-between  pb-[69px]">
        <div className="  pt-[100px] lg:pt-[100px] w-[100%]   lg:w-[95%] ">
          <div
            className="text-start"
            style={{
              color: "black",
              fontSize: 28,
              fontFamily: "Lora",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            Portfolio’s
          </div>
          <div className="overflow-x-auto   pt-[50px] md:pt-[40px]  ">
            <table className="min-w-full border-b-[1px] border-b-[#D4D4D4] gap-[10px] mb-[50px]">
              <thead>
                <tr>
                  <th className="text-start text-[14px] lg:text-[20px] text-[#424242] lg:leading-[30px] font-bold fontClass tracking-custom mb-[50px] pb-[5px] lg:pb-[26px] ">
                    Name
                  </th>
                  {/* <th className=" ml-[15px] text-start text-[14px] lg:text-[20px]  text-[#424242] lg:leading-[30px] font-bold fontClass tracking-custom mb-[50px] pb-[5px] lg:pb-[26px] pl-[10px]">
                    PROJECT TYPE
                  </th> */}
                  <th className=" ml-[15px] text-start text-[14px] lg:text-[20px] text-[#424242] lg:leading-[30px] font-bold fontClass tracking-custom mb-[50px] pb-[5px] lg:pb-[26px] pl-[10px]">
                    PRODUCTS USED
                  </th>
                  <th className="text-start pb-[26px]"></th>
                </tr>
              </thead>
              {currentListings1.map((item, index) => (
                <tbody key={index} className="border-b-[2px] border-gray-200">
                  {/* Example row, adjust data accordingly */}
                  <tr>
                    <td className="text-start text-[12px] pb-[15px]  lg:text-[18px] text-[#1D1D1D] lg:leading-[23.40px] font-normal fontClass2  pt-[18px]">
                      {item.projectTitle1}
                    </td>
                    {/* <td className=" ml-[15px] text-start text-[12px] pb-[15px] lg:text-[18px] pl-[15px] text-[#1D1D1D] lg:leading-[23.40px] font-normal fontClass2  pt-[18px]">
                      Asphalt Shingles
                    </td> */}
                    <td className=" ml-[15px] text-start text-[12px] pb-[15px] lg:text-[18px] pl-[15px] text-[#1D1D1D] lg:leading-[23.40px] font-normal fontClass2  pt-[18px]">
                      {item.productUsed}
                    </td>

                    <td className="pl-[15px]">
                      <Link to="/edit" state={{ item }}>
                        <div className="text-center   mt-[16px] h-[30px] w-[94px]  p-[4px] bg-[#54AACD] font-bold text-[15px] text-[white]  rounded-[9px] fontClass2 tracking-custom ">
                          Edit
                        </div>
                      </Link>
                    </td>
                    <td className="text-start pt-[8px] cursor-pointer font-bold text-[16px] text-[#0F1C32] lg:leading-[24px]  fontClass tracking-custom pl-[10px]">
                      <div
                        onClick={() => deleteItem(item?._id)}
                        style={{
                          color: "#BF0000",
                          fontSize: 15,
                          fontFamily: "Raleway",
                          fontWeight: "700",
                          lineHeight: "24.30px",
                          wordWrap: "break-word",
                        }}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              ))}
            </table>
          </div>


          <Pagination

            currentPage={currentPage1}
            totalPages={Math.ceil(data.length / itemsPerPage1)}
            onPageChange={handlePageChange1}
          />
          {/* <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#54AACD",
                fontSize: 24,
                fontFamily: "Lora",
                fontWeight: "400",
                lineHeight: "38.88px",
                wordWrap: "break-word",
              }}
            >
              1
            </div>
            <div
              style={{
                textAlign: "center",
                color: "#54AACD",
                fontSize: 24,
                fontFamily: "Lora",
                fontWeight: "400",
                lineHeight: "38.88px",
                wordWrap: "break-word",
              }}
            >
              2
            </div>
          </div> */}
        </div>

        <div className=" absolute flex gap-[10px] justify-center items-center  top-[30px] right-[30px] lg:top-[55px] ">
          <img
            src={require("../assets/logo.png")}
            alt=""
            className="h-[43px] w-[43px]"
          />
          <div className="text-[black] text-[18px] fontClass1 font-medium ">
            {storedUser.name}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
