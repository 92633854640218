import React, { useState } from "react";
import BG from "../assets/bg.png";
import BG1 from "../assets/bbg.png";
import { useLocation } from 'react-router-dom';
import { FaRegEyeSlash } from "react-icons/fa6";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../instance";
const Reset = () => {
  const location = useLocation();

  // Get the URL search params
  const searchParams = new URLSearchParams(location.search);

  // Extract the token from the search params
  const token = searchParams.get("token");

  // Use the token as needed
  console.log(token);
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newPassword: "",
    token: token
  });
  console.log("Token from URL:", token);
  const handlePassword = () => {
    setPasswordShow(!passwordShow);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/resetAdmin`,
        formData
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        Swal.fire({
          icon: "success",
          title: "Reset",
          text: "Your Password Reset Successfully",
        });
        navigate("/adminLogin");
        // Login successful, handle the data or redirect to another page
        console.log("Login successful:", response.data);
      } else {
        // Login failed, handle the error
        console.error("Login failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  return (
    <div
      className="h-screen flex justify-center items-center   px-[30px]  "
      style={{
        height: "100vh", // Set the desired height
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col  items-center bg-[#FFFFFF]  px-[30px]  md:px-[88px]  rounded-[10px] pb-[59px]">
        <div className="pt-[56px] font-bold  text-[26px] md:text-[36px] text-[#000000] leading-[46.08px] fontClass text-center ">
          Reset Password
        </div>
        <div className="mt-[11px] font-normal text-[16px] md:text-[20px] text-[#464646] leading-[25.6px] fontClass text-center ">
          Please fill in your unique admin Reset Password details below
        </div>

        <div className="w-full text-start">
          <div className=" mt-[25px]  md:mt-[45px] font-normal text-[18px] md:text-[24px] text-[#666666] leading-[32px] fontClass text-start flex ">
            New Password
          </div>
          <div className=" mt-[10px] md:mt-[18px] h-[58px] w-full flex justify-between items-center bg-[#E8E8E8] rounded-[10px] ">
            <input
              type={passwordShow ? "text" : "password"}
              name="newPassword" // Set the name attribute for the email input
              id=""
              value={formData.newPassword}
              onChange={handleInputChange}
              className="bg-[#E8E8E8] h-[58px] w-full rounded-[10px] border-none outline-none appearance-none pl-[10px] "
            />{" "}
            {/* Use type="password" for password input */}
            <div className="pr-[17px]">
              {" "}
              {passwordShow ? (
                <IoMdEye
                  fill="#999999"
                  onClick={handlePassword}
                  fontSize={24}
                />
              ) : (
                <FaRegEyeSlash
                  fill="#999999"
                  onClick={handlePassword}
                  fontSize={24}
                />
              )}{" "}
            </div>
          </div>
        </div>


        <div
          className="h-[52px]  mt-[25px] md:mt-[41px] w-full rounded-[10px] py-[20px] text-center flex justify-center items-center "
          style={{
            // Set the desired height
            backgroundImage: `url(${BG1})`,
          }}
        >
          <button
            className="text-[24px] font-bold leading-[32px] pt-[20px] pb-[20px] text-[#FFFFFF] "
            onClick={handleLogin}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reset;
