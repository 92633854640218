import React from "react";
import ImgBack from "../assets/Home/detail.jpeg";
import NavScrollExample from "./UserNavbar";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../instance";
const Detail = () => {
  const location = useLocation();

  // Access the data passed from the previous page
  //   const { state } = location;
  const data = location.state.step;
  console.log("data", data);
  const backImage = "../../";
  return (
    <>
      <div
        className="  h-[350px] md:h-[559px] w-full bg-cover "
        style={{
          backgroundImage: `url(${ImgBack})`,
          backgroundPosition: "center", // Adjust as needed
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-[#0000009E] h-[100%] ">
          <NavScrollExample />

          <div className="w-full  mt-[70px] md:mt-[180px]   flex justify-center items-center  ">
            <div className="text-white text-[40px] md:text-[65.49px] font-bold fontClass break-words">
              Portfolio
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[56px] pb-[89px]  pl-[30px] pr-[30px]  w-full  lg:w-[1100px]  mx-auto ">
        <div class="text-black   text-[26px] md:text-[32px]      font-semibold text-start font-lora break-words">
          {data.projectTitle}
        </div>
        {/* <div class="w-full text-[#1D1D1D]    mt-[16px] md:mt-[27px] text-[14px]  md:text-[16px]  text-start font-normal font-raleway leading-[25px] md:leading-7 break-words">
          {data.description}
        </div> */}
        {/* 
        <div class="w-full mt-[18px] text-[#414141] text-[14px]  md:text-[16px] text-start font-bold font-raleway leading-[25px] md:leading-7  break-words">
          Whether you need just a roof or the entire envelope of your home
          redone, we are your one-stop-shop. Work with experts that will manage
          coordinating each phase of your project seamlessly. Giving your home a
          fresh new look with updated materials increasing the appeal, value,
          and longevity of your investment.
        </div> */}
        <div className=" mt-[16px] md:mt-[27px]  flex justify-center items-center">
          <img
            src={`${BASE_URL}/profile/${data?.project1Image}`}
            alt=""
            className="width-class2 "
          />
        </div>
        <div class="text-black  text-[26px] md:text-[32px] text-start mt-[37px] font-semibold font-lora break-words">
          {data.projectTitle1}
        </div>
        {/* <div class=" mt-[16px] md:mt-[27px] w-full text-[#1D1D1D] text-start text-[14px]  md:text-[16px] font-normal font-raleway leading-[25px] md:leading-7  break-words">
          {data.description1}
        </div> */}
        <div className="  flex flex-col  justify-center items-centers   lg:flex-row lg:justify-center lg:items-center gap-[39px] mt-[27px]   ">
          <img
            src={require("../assets/Home/d1 (3).png")}
            alt=""
            className="w-full width-class h-[360px] "
          />
          <img
            src={`${BASE_URL}/profile/${data?.homeproject1Image}`}
            alt=""
            className=" w-full width-class h-[360px]"
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Detail;
