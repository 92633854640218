import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
function NavScrollExample() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);
  const handleOffcanvasClose = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  const handleNavLinkClick = (section) => {
    // Navigate to the Home page with the specified section's hash
    window.location.href = `/#${section}`;
  };
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className=" pr-[10px] lg:pr-[62px] md:pr-[10px]   pl-[10px] md:pl-[62px] pt-[23px]"
        >
          <Container fluid>
            <Link to="/">
              <Navbar.Brand>
                {" "}
                <img
                  src={require("../assets/logo2.png")}
                  alt=""
                  className="w-[140px] h-[80px]"
                />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle
              onClick={handleOffcanvasClose}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="bg-[white]"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={showOffcanvas}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className=" justify-between items-center"
                >
                  <div className="pb-[50px]">
                    <button
                      type="button"
                      className="text-red-500 focus:outline-none"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <IoCloseSharp size={30} onClick={handleOffcanvasClose} />
                    </button>
                  </div>
                  <img
                    src={require("../assets/logo2.png")}
                    alt=""
                    className="w-[134px] h-[47px]"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium " >
                    <Link to='/' >Home
                    </Link>
                  </Nav.Link>{" "}
                  <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
                  <Link to="/Value">
                  About
                  </Link>
                  </Nav.Link>
                  <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
                    {" "}
                    <a href="/" onClick={() => handleNavLinkClick("portfolio")}>Portfolio</a>
                  </Nav.Link>
                  <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
                  <a href="/" onClick={() => handleNavLinkClick("faq")}>FAQ</a>
                  </Nav.Link>
                  <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
                  <a href="/" onClick={() => handleNavLinkClick("bookappoint")}>Book Appointment</a>
                  </Nav.Link>
                </Nav>
                <Form className="d-flex">
                  <div className="flex justify-center items-center gap-[10px] back-div mt-[20px] lg:mt-[0px]  rounded-[6px] px-[10px] py-[5px] ">
                    <img
                      src={require("../assets/Value/bxs_phone-call.png")}
                      alt=""
                      className="w-[24px] h-[24]"
                    />
                    <div className="text-[#FFF] text-center text-[16px] font-normal font-poppins break-words">
                      780-297-7663
                    </div>
                  </div>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>

    // <Navbar expand="lg" className="pr-[62px]  pl-[62px] pt-[23px]">
    //   <Container fluid>
    //     <Navbar.Brand href="#">
    //       <img
    //         src={require("../assets/logo2.png")}
    //         alt=""
    //         className="w-[134px] h-[47px]"
    //       />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="navbarScroll" />
    //     <Navbar.Collapse id="navbarScroll">
    //       <Nav className="mx-auto" style={{ maxHeight: "100px" }} navbarScroll>
    //         <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
    //           Home
    //         </Nav.Link>
    //         <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
    //           About
    //         </Nav.Link>
    //         <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
    //           Portfolio
    //         </Nav.Link>
    //         <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
    //           FAQs
    //         </Nav.Link>
    //         <Nav.Link className="text-[white] text-[16px] fontClass2 font-medium ">
    //           Book Appointment
    //         </Nav.Link>
    //       </Nav>
    //       <Form className="d-flex">
    //         <Button className="w-[136px] border-none  back-div h-[43px] text-[white] text-[16px] fontClass2  font-semibold">
    //           Contact US
    //         </Button>
    //       </Form>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
}

export default NavScrollExample;
