import React, { useEffect, useState } from "react";
import ImgBack from "../assets/Home/bg.jpeg";
import NavScrollExample from "./UserNavbar";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
import SimpleSlider from "./Slider";
import axios from "axios";
import AutoPlay from "./MainSlider";
import Swal from "sweetalert2";
import { BASE_URL } from "../../instance";
const Home = () => {
  const [data, setData] = useState([]);

  const [inspection, setInspection] = useState({
    fullName: "",
    Address: "",
    needs: "",
    number: "",
    service: "",
  });

  console.log("inspection", inspection);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInspection((prevInspection) => ({
      ...prevInspection,
      [name]: value,
    }));
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/allData`); // Update the URL based on your API server
      setData(response?.data);
      console.log("response", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log("data", data);

    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the API endpoint using Axios
      const response = await axios.post(
        `${BASE_URL}/api/sendData`,
        inspection,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the request was successful
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Inspection",
          text: "Your Information Submit Successfully",
        });
        console.log(response.data.message); // Log success message
        // You can also perform additional actions on success
      } else {
        console.error("Failed to send data"); // Log error message
      }
    } catch (error) {
      console.error("Error:", error); // Log any other errors
    }
  };
  useEffect(() => {
    // Check if the URL contains any of the section hashes
    const sectionHashes = ["faq", "bookappoint", "portfolio"];
    const urlHash = window.location.hash.substring(1);
    if (sectionHashes.includes(urlHash)) {
      // Find the corresponding section in the DOM by its ID
      const sectionElement = document.getElementById(urlHash);
      if (sectionElement) {
        // Scroll to the section
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const backImage = "../../";
  return (
    <>
      <div
        className=" h-[450px] md:h-[757px] w-full bg-cover "
        style={{
          backgroundImage: `url(${ImgBack})`,
          backgroundPosition: "center", // Adjust as needed
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-[#0000009E]  h-[100%] ">
          <NavScrollExample />

          <div className="w-full mt-[40px] md:mt-[163px] text-start pl-[30px]  pb-[230px]  flex flex-col justify-center items-center  ">
            <div className="text-center">
              <span className="text-white  text-[30px] md:text-[48px] font-bold fontClass   leading-[61.44px] break-words">
                Edmonton's Residential
                <br />
              </span>
              <span className="text-[#54AACD] text-[30px]   leading-[61.44px] md:text-[48px] text-center font-bold fontClass break-words">
                Roofing Experts
              </span>
            </div>
            {/* <div className="lg:w-[674px] text-white text-start font-light leading-7 font-raleway break-words">
              Educating clients about exterior home renovation projects is
              crucial to help them make informed decisions. We start the process
              with a free consultation.
            </div> */}

            <button className="mt-[36px] w-[188px] border-none  back-div h-[50px] text-[white] text-[16px] fontClass2  font-semibold rounded-[8px]">
              Get Estimate
            </button>
          </div>
        </div>
      </div>

      <div className="pt-[56px] pb-[56px]">
        <div className="    flex flex-col  justify-center items-center  lg:flex-row justify-between items-start gap-[46px] home-p1age mx-auto  ">
          <div>
            {/* <div className="text-start  text-[#3A75BB] text-lg font-bold font-raleway leading-7 break-words">
              About us
            </div> */}

            <div className="md:w-[394px]  mt-[16px] text-start text-black  text-[26px] md:text-[32px] font-bold font-lora leading-[41.60px] break-words">
              Committed to Roofing Excellence
            </div>

            {/* <div className="lg:w-[697px] lg:pl-[51px] mt-[39px] text-gray-700 text-start font-bold font-raleway leading-7 break-words">
              We believe in investing in excellence, starting with our dedicated
              teams. We compensate our crews above industry standards because we
              know that quality work comes from quality people. This commitment
              ensures we retain the finest crews, who, in turn, deliver
              exceptional craftsmanship.
            </div> */}
            <div className="lg:w-[697px] lg:pl-[51px] mt-[18px] text-gray-800 text-start font-normal font-raleway leading-7 break-words">
              In our pursuit of excellence , safety stands as our paramount
              priority. We uphold stringent safety measures to ensure the
              well-being of both our team members and clients. Our commitment to
              perfection in every job undertaken is unwavering, emphasizing
              precision and quality craftsmanship to deliver flawless roofing
              solutions. Recognizing the importance of time, we are dedicated to
              completing projects in a timely fashion without compromising on
              the precision and excellence that define our work. Furthermore, we
              pride ourselves on offering fair and transparent pricing
              structures, ensuring our clients receive exceptional value for
              their investment. Our goals are rooted in creating a roofing
              experience characterized by safety, perfection, timeliness, and
              fairness, reflecting our steady dedication to client satisfaction.
            </div>
          </div>

          <div className="  flex justify-center items-center  lg:gap-[35px] ">
            {/* <div className="flex justify-center items-center gapClass">
              <div>
                <div className="flex justify-center">
                  <img
                    src={require("../assets/Home/carbon_face-satisfied-filled.png")}
                    className="w-[87px] h-[87px]"
                    alt=""
                  />
                </div>
                <div className="mt-[20px] text-center text-gray-800 text-6xl font-semibold font-lora leading-14 break-words">
                  2680
                </div>
                <div className="text-[#3A75BB] mt-[5px] text-center font-semibold font-raleway leading-7 break-words">
                  Satisfied Clients
                </div>
              </div>
              <div>
                <div className="flex justify-center">
                  <img
                    src={require("../assets/Home/Frame 3.png")}
                    className="w-[87px] h-[87px]"
                    alt=""
                  />
                </div>
                <div className="mt-[20px] text-center text-gray-800 text-6xl font-semibold font-lora leading-14 break-words">
                  21
                </div>
                <div className="text-[#3A75BB] mt-[5px] text-center font-semibold font-raleway leading-7 break-words">
                  Team Members
                </div>
              </div>
            </div> */}

            <div className="flex justify-center items-center gap-[100px] lg:gapClass">
              <div>
                <div className="flex justify-center">
                  <img
                    src={require("../assets/Home/fluent-mdl2_completed-solid.png")}
                    className="w-[87px] h-[87px]"
                    alt=""
                  />
                </div>
                <div className="mt-[20px] text-center text-gray-800 text-6xl font-semibold font-lora leading-14 break-words">
                  1000+
                </div>
                <div className="text-[#3A75BB] mt-[5px] text-center font-semibold font-raleway leading-7 break-words">
                  Projects Completed
                </div>
              </div>
              {/* <div>
                <div className="flex justify-center">
                  <img
                    src={require("../assets/Home/Frame 2.png")}
                    className="w-[87px] h-[87px]"
                    alt=""
                  />
                </div>
                <div className="mt-[20px] text-center text-gray-800 text-6xl font-semibold font-lora leading-14 break-words">
                  3
                </div>
                <div className="text-[#3A75BB] mt-[5px] text-center font-semibold font-raleway leading-7 break-words">
                  Awards Won
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="back-div pb-[51px] pl-[30px] pr-[30px]  md:pl-[0px] md:pr-[0px] ">
        <div className="text-white pt-[51px]  text-[16px]  md:text-[20px] fontClass2 font-raleway font-bold break-words">
          Our guarantee ensures your home stays protected.
        </div>

        <div className=" mt-[14px] text-white  text-[28px] md:text-[36px] fontClass font-lora font-bold break-words">
          10-Year Workmanship Warranty.
        </div>
        <div className="md:w-[513px] mx-auto mt-[14px] text-[14px] md:text-[16px] text-center text-white  font-raleway font-normal leading-[22.69px] break-words">
          When we leave your home, you can rest assured that your roof is built
          with the craftsmanship you'd expect from the best roofing company in
          Edmonton. Our work isn't finished until the site is inspected and
          fully cleaned—that includes your gutters too!
        </div>

        <div className="mt-[29px] flex justify-center items-center gap-[24px]">
          <div className="text-center px-[10px] md:px-[32px] rounded-[8px] py-[10px] md:py-[15px] border-[1px] border-[white] text-white   text-[14px] md:text-[18px]   fontClass2 font-raleway font-bold break-words">
            GET IN TOUCH
          </div>
          <div className="text-center  px-[10px] md:px-[32px] rounded-[8px] py-[10px] md:py-[15px]  bg-[white] text-[#3B78BC] text-[14px] md:text-[18px] fontClass2 font-raleway font-bold break-words">
            ABOUT US
          </div>
        </div>
      </div>

      <div id="portfolio">
        <SimpleSlider />
      </div>
      {/* 
      <div className="pt-[65px] back-div pb-[65px]">
        <div className="text-center text-[#FFFFFF]   pl-[20px] pr-[20px]   text-[20px] md:text-[32px] font-Lora font-bold leading-8 break-all">
          Our trusted partner companies
        </div>
        <div className="mt-[65px]   ">
          <AutoPlay />
        </div>
      </div> */}

      <div className="bg-[white] pb-[56px]">
        <div
          id="faq"
          className=" pt-[83px] pb-[23px] text-[#54AACD] text-2xl font-bold font-raleway leading-10 break-words"
        >
          FAQs
        </div>

        <div className=" w-full   pl-[20px] pr-[20px] md:pl-[50px] md:pr-[50px]   lg:w-[1023px] mx-auto ">
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How long does asphalt roofing typically last
              </Accordion.Header>
              <Accordion.Body>
                Asphalt shingles usually have a lifespan of 20-30 years,
                depending on factors like climate, maintenance, and quality of
                installation.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                *What is the difference between 3-tab and architectural
                shingles?*
              </Accordion.Header>
              <Accordion.Body>
                tab shingles are flat and have a uniform appearance, while
                architectural shingles have a multi-dimensional look with
                varying shapes and sizes, providing a more aesthetically
                pleasing appearance.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                *How can I maintain my asphalt roof to prolong its lifespan?*
              </Accordion.Header>
              <Accordion.Body>
                Regular inspections, keeping gutters clean, removing debris,
                repairing any damaged shingles promptly, and ensuring proper
                attic ventilation can help maintain the longevity of your
                asphalt roof.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                *Are asphalt shingles fire-resistant?*
              </Accordion.Header>
              <Accordion.Body>
                Most asphalt shingles have a Class A fire rating, which means
                they offer the highest level of fire resistance.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Can asphalt shingles withstand harsh weather conditions?*
              </Accordion.Header>
              <Accordion.Body>
                Asphalt shingles are designed to withstand various weather
                conditions, including wind, rain, snow, and hail. However,
                extreme weather events can still cause damage.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                *How do I know if my asphalt roof needs to be replaced?*
              </Accordion.Header>
              <Accordion.Body>
                Signs such as curling or missing shingles, granule loss, leaks,
                and visible damage indicate that your asphalt roof may need
                replacement.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                *Are asphalt shingles environmentally friendly?*
              </Accordion.Header>
              <Accordion.Body>
                While asphalt shingles can be recycled, their production and
                disposal still have environmental impacts. However, there are
                eco-friendly options available, such as shingles made from
                recycled materials or those designed for energy efficiency.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                *How much does asphalt roofing cost?*
              </Accordion.Header>
              <Accordion.Body>
                The cost of asphalt roofing can vary depending on factors like
                the type of shingles, the size and complexity of the roof, and
                the location. Generally, asphalt roofing is one of the most
                cost-effective options for residential roofing.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {/* Appoinmnet form */}
      </div>
      <div className="w-full back-div pt-[45px] pb-[35px] " id="bookappoint">
        <div className="formwidthClass mx-auto">
          <div className="text-white text-[32px] font-bold font-lora fontClass text-center leading-10 break-words">
            Get Your Estimate
          </div>
          <div className="md:w-[513px] text-center mx-auto mt-[26px] text-[#FFFFFF] text-[16px] font-normal  fontClass2 leading-[22.69px]">
            A Complementary roof inspection is to receive an accurate estimate
            tailored to your needs.
          </div>

          <div className="   flex flex-col  md:flex-row justify-center items-start gap-[26px] pl-[26px]">
            <div className="w-full">
              <div className="w-full flex flex-col md:flex-row justify-center items-center gap-[20px]">
                <div className=" w-full mt-[25px]">
                  <div className="text-white text-color rounded-[16px] text-start text-color font-normal font-raleway leading-7 break-words">
                    Full Name
                  </div>
                  <input
                    type=""
                    name="fullName"
                    value={inspection.fullName}
                    onChange={handleChange}
                    placeholder="Full Name"
                    className="bh-white w-full  rounded-[14px] pl-[20px]  mt-[5px] h-[57px]"
                  />
                </div>
                <div className=" w-full md:mt-[25px]">
                  <div className="text-white text-color rounded-[16px] text-start text-color font-normal font-raleway leading-7 break-words">
                    Phone Number
                  </div>
                  <input
                    type="number"
                    name="number"
                    value={inspection.number}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    className="bh-white w-full  rounded-[14px] pl-[20px]  mt-[5px] h-[57px]"
                  />
                </div>
              </div>
              {/* <div className="w-full mt-[25px] ">
                <div className="text-white text-start text-color font-normal font-raleway leading-7 break-words">
                  Email*
                </div>
                <input
                  type=""
                  placeholder="First Name"
                  className="bg-white w-full rounded-[14px] pl-[20px]  mt-[5px] h-[57px]"
                />
              </div> */}
              <div className="w-full mt-[25px] rounded-[16px]">
                <div className="text-white text-start text-color font-normal font-raleway leading-7 break-words">
                  Address
                </div>
                <input
                  type=""
                  name="Address"
                  value={inspection.Address}
                  onChange={handleChange}
                  placeholder="Enter Address"
                  className="bg-white w-full rounded-[14px] pl-[20px]  mt-[5px] h-[57px]"
                />
              </div>
              <div className="w-full mt-[25px] rounded-[16px]">
                <div className="text-white text-start text-color font-normal font-raleway leading-7 break-words">
                  Explain your roofing needs*
                </div>
                <input
                  type=""
                  name="needs"
                  value={inspection.needs}
                  onChange={handleChange}
                  placeholder=" Explain your roofing needs"
                  className="bh-white w-full rounded-[14px] pl-[20px]  mt-[5px] h-[57px]"
                />
              </div>
              <div className="w-full  flex flex-col     lg:flex-row  justify-between items-start  mt-[25px]">
                <div className="text-white  text-start font-semibold font-raleway leading-7 break-words">
                  What services are you interested in?*
                </div>

                <div className="flex flex-col     lg:flex-row  justify-start items-start  lg:gap-[40px]">
                  <div className="mt-[9px]">
                    <input
                      type="radio"
                      id="option1"
                      name="service"
                      value="Re-roofing estimate"
                      className="mr-2"
                      checked={inspection.service === "Re-roofing estimate"}
                      onChange={handleChange}
                    />
                    <label htmlFor="option1" className="text-gray-700">
                      <div className="text-white text-base font-normal font-raleway leading-7 break-words">
                        Re-roofing estimate
                      </div>
                    </label>
                  </div>
                  <div className="mt-[9px]">
                    <input
                      type="radio"
                      id="option2"
                      name="service"
                      value="Roof Maintenance Check"
                      className="mr-2"
                      checked={inspection.service === "Roof Maintenance Check"}
                      onChange={handleChange}
                    />
                    <label htmlFor="option2" className="text-gray-700">
                      <div className="text-white text-base font-normal font-raleway leading-7 break-words">
                        Roof Maintenance Check
                      </div>
                    </label>
                  </div>
                  <div className="mt-[9px]">
                    <input
                      type="radio"
                      id="option3"
                      name="service"
                      value="Roof Repair"
                      className="mr-2"
                      checked={inspection.service === "Roof Repair"}
                      onChange={handleChange}
                    />
                    <label htmlFor="option3" className="text-gray-700">
                      <div className="text-white text-base font-normal font-raleway leading-7 break-words">
                        Roof Repair
                      </div>
                    </label>
                  </div>
                  <div className="mt-[9px]">
                    <input
                      type="radio"
                      id="option4"
                      name="service"
                      value="New Build"
                      className="mr-2"
                      checked={inspection.service === "New Build"}
                      onChange={handleChange}
                    />
                    <label htmlFor="option4" className="text-gray-700">
                      <div className="text-white text-base font-normal font-raleway leading-7 break-words">
                        New Build
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="w-full mt-[25px] rounded-[16px]">
                <div className="text-white text-start font-normal font-raleway leading-7 break-words">
                  Select Date & Time
                </div>
                <select
                  class="bg-white w-full  text-grey rounded-[14px] pl-[20px]  mt-[5px] h-[57px] focus:outline-none focus:border-none"
                  name="Time"
                  value={inspection.Time}
                  onChange={handleChange}
                >
                  <option
                    value=" 06/24/2021 - 09/24/2021"
                    className="text-grey"
                  >
                    06/24/2021 - 09/24/2021
                  </option>
                  <option
                    value=" 07/24/2021 - 08/24/2021"
                    className="text-grey"
                  >
                    07/24/2021 - 08/24/2021
                  </option>
                  <option value="02/24/2021 - 03/24/2021" className="text-grey">
                    02/24/2021 - 03/24/2021
                  </option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div
          onClick={handleSubmit}
          className="bg-[white] rounded-[8px] mt-[50px] py-[15px] w-[254px] h-[52px] mx-auto text-center text-[#3B78BC] text-[18px] font-raleway font-bold break-words"
        >
          Book Your Inspection
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
