import React from "react";
import ImgBack from "../assets/Value/v.jpeg";
import ImgBack1 from "../assets/Subtract.png";

import Accordion from "react-bootstrap/Accordion";
import NavScrollExample from "../UserPannel/UserNavbar";
import Footer from "../UserPannel/Footer";
import Pic1 from "../assets/Value/Guarantee.png";
import Pic2 from "../assets/Value/Acupuncture Point.png";
import Pic3 from "../assets/Value/Tags.png";
import Pic4 from "../assets/Value/Protect.png";
import Pic5 from "../assets/Value/e.png";
import ValueSlider from "../UserPannel/ValueSlider";
import MultipleItems from "../UserPannel/ValueSlider";

const Value = () => {
  const data = [
    {
      stepNO: "Step 1",
      title: "Initial contact",
      detail:
        "Speak with one of our team's sales professionals, who are also trained installers, to find out what product suits your needs best. Schedule an on- site appointment or receive a quote safely by email.",
    },
    {
      stepNO: "Step 2",
      title: "Free Estimate",
      detail:
        "Our knowledgeable staff will ensure that any questions you have are answered. Our estimates cover the entire roofing process, price, and the right product for the job. All estimates will be emailed to retain for future records.",
    },
    {
      stepNO: "Step 3",
      title: "Professional Installation",
      detail:
        "Our in-house installers will replace all existing roof material with your brand-new roofing system. The roof decking will be inspected upon removal of existing roof and replaced if necessary. The entire job site will be cleaned daily, including a magnet run for any leftover nails.",
    },
    {
      stepNO: "Step 4",
      title: "Roof Inspection",
      detail:
        "Our roof inspector inspects every job upon completion. Each new roof we install comes with a 10-year workmanship warranty to ensure your roof is made to last. Roof inspection can be scheduled to address any questions at that time.",
    },
    {
      stepNO: "Step 5",
      title: "Invoicing",
      detail:
        "Only after roof inspection has been approved will our invoice be sent. Invoice are sent electronically via email where customers can pay online or mail a cheque. At this time, we hope to receive your feedback in a review at your convenience!",
    },
  ];

  const data1 = [
    {
      stepNO: "1",
      title:
        "Schedule a complimentary roof inspection to receive an accurate estimate tailored to your needs.",
    },
    {
      stepNO: "2",
      title:
        "Once the inspection is complete, we'll promptly order the necessary materials for delivery. For larger projects and full reroofs, a deposit for materials may be required",
    },
    {
      stepNO: "3",
      title:
        "Our dedicated team will work efficiently to complete the project within 1-3 days, factoring in size and weather conditions. For larger undertakings like condos and apartments, the timeline may extend up to a week or two",
    },
    {
      stepNO: "4",
      title:
        "Post-installation, meticulous cleanup is our priority. We adhere to strict safety standards, conducting thorough ground cleanup and utilizing a large magnet to collect any stray nails",
      detail:
        "Our roof inspector inspects every job upon completion. Each new roof we install comes with a 10-year workmanship warranty to ensure your roof is made to last. Roof inspection can be scheduled to address any questions at that time.",
    },
    {
      stepNO: "5",
      title:
        "Convenient payment options include cheque, debit, credit, or e-transfer for work completed and disposal. Upon payment, customers will receive essential paperwork, including the shingle manufacturer warranty and our company's workmanship warranty certificate.",
    },
  ];

  const commitment = [
    {
      img: Pic1,
      title: "Quality",
      Para: "At our company, our craftsmanship sets the standard, ensuring every detail of your roof is meticulously crafted to perfection, guaranteeing durability, beauty, and lasting value for your home.",
    },
    {
      img: Pic2,
      title: "Safe work practices",
      Para: "We prioritize safety above all else, implementing rigorous protocols and training to ensure every aspect of your roofing project is completed with the highest standards of safety, giving you peace of mind throughout the entire process.",
    },
    {
      img: Pic3,
      title: "Affordable pricing",
      Para: "We pride ourselves on offering competitive and affordable pricing without compromising on quality, ensuring you receive exceptional value for your roofing needs without breaking the bank.",
    },
    {
      img: Pic4,
      title: "Warranty",
      Para: "Here at Edmonton Asphalt Roofing we stand behind our workmanship with confidence, offering a 10-year warranty to guarantee the quality and durability of our roofing installations, providing you with long-term peace of mind and satisfaction.",
    },
  ];

  const backImage = "../../";
  return (
    <div className="overflow-hidden">
      <div
        className="  h-[350px] md:h-[559px] w-full bg-cover "
        style={{
          backgroundImage: `url(${ImgBack})`,
          backgroundPosition: "center", // Adjust as needed
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-[#0000009E] h-[100%] ">
          <NavScrollExample />

          <div className="w-full  mt-[70px] md:mt-[180px]   flex justify-center items-center  ">
            <div className="text-white text-[40px] md:text-[65.49px] font-bold fontClass break-words">
              About us
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-[100px] fontclass2 font-bold text-[40px] leading-[52px] text-center">
        Our Process
      </div>

      <div className="  mt-[100px] flex justify-center items-center gap-[20px]   flex-wrap ">
        {data1.map((item, index) => {
          return (
            <>
              <div className="flex flex-col">
                <div className=" ml-[40px]  back-div text-[#FFFFFF]   rounded-[50%] text-[40.48px] font-normal fontClass2 leading-[71.4px]  mb-[-80px] w-[151px] h-[151px]  flex justify-center items-center ">
                  {item.stepNO}
                </div>

                <div
                  className="w-[229px] h-[353px]   pt-[60px] flex justify-center  items-center  "
                  style={{
                    backgroundImage: `url(${ImgBack1})`,
                    backgroundPosition: "center", // Adjust as needed
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="w-[178px]   text-[12.65px]  text-[#1D1D1D] ">
                    {item.title}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      {/* <div className="pt-[83px] pb-[89px]  w-full ">
        <div className="flex flex-wrap justify-between gap-[0px] lg:gap-[0px] formwidthClass mx-[auto]">
          {data.map((item, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
              <div className=" mb-[67px]">
                <div className="text-[#3A75BB] fontClass2 text-start text-[22px] font-raleway font-bold leading-[28.6px] break-words">
                  {item.stepNO}
                </div>
                <div className="w-full mt-[14px] fontClass2 text-black text-start text-[32px] font-raleway font-bold leading-[41.6px] break-words">
                  {item.title}
                </div>
                <div className="mt-[14px] detailwidth text-[#414141] fontClass2 text-start font-raleway font-normal leading-6 break-words">
                  {item.detail}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className=" mt-[100px] pt-[90px] pb-[90px] pl-[30px] pr-[30px] back-div ">
        <div className="text-[white]  text-[28px] md:text-[36px] fontClass font-lora font-bold break-words">
          Our commitment to you!
        </div>

        <div className=" flex text-center gap-[0px]   justify-between md:justify-around items-center mt-[68px] flex-wrap">
          {commitment.map((item, index) => (
            <div
              key={index}
              className="flex    text-center justify-center tems-center w-full   sm:w-[35%] md:w-1/2 lg:w-1/4 mb-4"
            >
              <div className="flex flex-col  justify-center items-center">
                <div
                  className="w-[110px] h-[110px] flex justify-center items-center"
                  style={{
                    backgroundImage: `url(${Pic5})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img src={item.img} alt="" className="w-[64px] h-[64px]" />
                </div>
                <div className="text-center mt-[23px] text-[white] text-[20px] fontClass2 font-lora font-bold break-words">
                  {item.title}
                </div>
                <div className="  padClas text-center mt-[10px] text-[white] text-[10px] fontClass2 font-lora font-normal break-words">
                  {item.Para}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="mt-[100px]  pb-[100px] ">
        <MultipleItems />
      </div> */}

      <Footer />
    </div>
  );
};

export default Value;
